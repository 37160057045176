.tbody {
  tr {
    &:nth-of-type(odd) {
      @apply bg-gray-50;
    }

    &:nth-of-type(even) {
      @apply bg-gray-100;
    }
  }
}

.tableCell {
  @apply p-3;
}
